import type { MetaFunction, LoaderFunctionArgs } from "@remix-run/node";
import { json } from "@remix-run/node";
import { Link, useLoaderData } from "@remix-run/react";
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import {CommandLineIcon, CircleStackIcon, CloudIcon, GlobeAltIcon, WrenchScrewdriverIcon, CpuChipIcon, ChartBarSquareIcon } from '@heroicons/react/24/outline';
import {chunk} from 'lodash-es';
import homeListJson from '../server/topics-data/homePage.json';
import {getAllTrendingRepos} from '../server/trending.server';
import {listTypeMap} from './trending.$listType';
import {apiCacheControlHeader} from '../server/utils.server';
import {spaceRepoName} from '../components/utils';

export const meta: MetaFunction = () => {
  const title = "Related Repos - Find alternatives and compare libraries";
  const description = "Related Repos helps you find alternatives and other similar open source repositories. Find the best package for your app. Results are updated daily.";
  const canonicalURL = __BASE_URL__;

  return [
    { title },
    { name: "description", content: description },
    { tagName: "link", rel: "canonical", href: canonicalURL },
    { property: "og:title", content: title },
    { property: "og:description", content: description },
    { property: "og:type", content: "website" },
    { property: "og:url", content: canonicalURL },
    { property: "og:image", content: __OG_IMAGE__ }
  ];
};

export async function loader({params}: LoaderFunctionArgs) {
  const repoResultsAll = getAllTrendingRepos();

  return json({
    repoResultsAll, 
    listTypeData: listTypeMap[params.listType as string]
  }, { headers: apiCacheControlHeader });
};

const iconMap = {
  "CommandLineIcon": CommandLineIcon,
  "CircleStackIcon": CircleStackIcon,
  "CloudIcon": CloudIcon,
  "GlobeAltIcon": GlobeAltIcon,
  "WrenchScrewdriverIcon": WrenchScrewdriverIcon,
  "CpuChipIcon": CpuChipIcon
}

// replace whitespaces with a dash
const getSectionAnchor = (section: string): string => section.replace(/\s+/g, '-');

const Section = ({sectionEntry}) => {
  const topicsChunked = chunk(sectionEntry.topics, 2);
  const IconToRender = iconMap[sectionEntry.icon] || CommandLineIcon;
        
  return (
    <div className="mb-4">
      <a id={getSectionAnchor(sectionEntry.section)}>
        <h3 className="font-semibold text-lg pl-4 flex items-center">
          <IconToRender className="size-5 mr-1 text-rr-light" />
          <span>{sectionEntry.section}</span>
        </h3>
      </a>

      {topicsChunked.map((topicsPair: any, i: number) => (
        <div className="flex flex-wrap" key={i}>
          <div className="w-full md:w-1/2 p-4 ">
            <RepoList listData={topicsPair[0]} linkUrl={`/topics/${topicsPair[0].topicName}`} />
          </div>
          {topicsPair[1] &&
          <div className="w-full md:w-1/2 p-4">
            <RepoList listData={topicsPair[1]} linkUrl={`/topics/${topicsPair[1].topicName}`} />
          </div>}
        </div>))}
    </div>
  )
}

const RepoList = ({listData, linkUrl}) => {
  return (
  <div className="border border-gray-300 rounded-lg p-3 background-gray">
    <div className="font-semibold text-base">
      <Link to={linkUrl}>{listData.listTitle} »</Link>
    </div>
    <ul role="list">
      {listData.repoNames.map((repoName: string, i: number) => (
        <li key={i} className="p-1 overflow-hidden text-ellipsis">
          {/* long repo name was overflowing and causing layout issues */}
          <Link to={`/gh/${repoName}`} className="text-base">{spaceRepoName(repoName)}</Link>
        </li>
      ))}
    </ul>
  </div>)
}

function getTrendingReposForList(loaderData, listType: string) {
  return loaderData.repoResultsAll.filter(r => r.list_type === listType).map(r => r.repo_name).slice(0,5);
}

export default function Index() {
  const loaderData: any = useLoaderData();

  const trendingReposToday = {
    listTitle: listTypeMap['today'].headerText,
    repoNames: getTrendingReposForList(loaderData, 'today')
  };

  const trendingReposWeekly = {
    listTitle: listTypeMap['weekly'].headerText,
    repoNames: getTrendingReposForList(loaderData, 'weekly')
  };

  return (
    <>
      <hr className="mt-4" />
      
      <div className="bg-gradient-to-b from-blue-50 to-white dark:from-gray-800 dark:to-sky-900 pt-4 pb-5">
        <h1 className="text-xl md:text-2xl text-center p-2 font-primary">
          Discover related open source projects. Updated daily.
        </h1>

        <p className="text-center text-rr-medium text-base">
          Find alternatives and other similar repositories
        </p>

        <div className="flex justify-center pt-3 pb-3">
          <Link to="/search">
            <div className="flex items-center mr-3 h-9 p-5 background-button-white border-2 border-gray-400 rounded-lg drop-shadow-md">
              <MagnifyingGlassIcon className="size-6 text-gray-400" />
              {/* ⊞ ? */}
              <span className="ml-2 mr-1 text-lg md:text-xl text-rr-button-white-primary">Search for a repository</span>
              <span className="hidden md:inline border dark:border-gray-400 rounded-md text-sm text-gray-400 p-0.5 ml-1">⌘K</span>
            </div>        
          </Link>
        </div>
      </div>
      
      <hr className="mb-4" />

      {/* Hide browser extenion section on mobile */}
      <div className="hidden md:flex justify-center items-center flex-wrap gap-y-4 pt-3 pb-3">
        <div className="text-xl pr-2">
          <div>Use the official <Link to="/extension" className="font-semibold">browser extension</Link> or <Link to="/bookmarklet" className="font-semibold">bookmarklet</Link> -</div>
          <div className="text-center text-rr-medium text-base">Quickly see results while browsing on GitHub</div>
        </div>
        <Link to="/extension">
          <img className="border border-gray-400" width="300" src="/images/extension_screenshot_cropped.png" alt="Browser extension screenshot" />
        </Link>
      </div>

      <hr className="mt-4 mb-4 hidden md:block" />

      <div className="mb-4">
        <div className="text-lg md:text-xl flex justify-center items-center">
          <ChartBarSquareIcon className="size-5 mr-1 text-rr-light" />
          <div>Trending</div>
        </div>
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 p-4 ">
            <RepoList listData={trendingReposToday} linkUrl="/trending/today" />
          </div>
          <div className="w-full md:w-1/2 p-4">
            <RepoList listData={trendingReposWeekly} linkUrl="/trending/weekly" />
          </div>
        </div>
      </div>

      <hr className="mt-4 mb-4" />

      <h2 className="text-lg md:text-xl text-center p-2 mb-1">
        Popular topics
      </h2>

      <div className="flex justify-center gap-x-5 gap-y-4 flex-wrap mb-6 mt-2 max-w-screen-md mx-auto">
        { homeListJson.map((sectionEntry: any, idx: number) => {
          const IconToRender = iconMap[sectionEntry.icon] || CommandLineIcon;

          return (
            // bg-gradient-to-br from-blue-200 to-white
            <Link key={idx} to={`#${getSectionAnchor(sectionEntry.section)}`} type="button" className="text-rr-button-blue-primary background-button-blue inline-flex items-center gap-x-1.5 rounded-full px-4 py-2.5 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300">
              <IconToRender className="text-rr-button-blue-light size-5 mr-1" />
              {sectionEntry.section}
            </Link>
          )
        }
          
        )}
      </div>

      { homeListJson.map((sectionEntry: any, idx: number) => <Section sectionEntry={sectionEntry} key={idx} /> )}
    </>
  );
}
